import React, { KeyboardEventHandler } from "react";
import CreatableSelect from "react-select/creatable";
import Button from "../Button";
import "./multiSelectInput.css";
const components = {
  DropdownIndicator: null,
};
interface Option {
  readonly label: string;
  readonly value: string;
}
const createOption = (label: string) => ({
  label,
  value: label,
});
interface IProps {
  text: string;
  placeholder?: string;
  name: string;
  required?: boolean;
  onValueChange: (value: any) => void;
}
const MultiSelectInput: React.FC<IProps> = ({
  text,
  placeholder,
  name,
  required,
  onValueChange,
}) => {
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState<readonly Option[]>([]);
  const handleClick = () => {
    if (!inputValue) return;
    if (value.find((option) => option.label === inputValue)) {
      setInputValue("");
      return;
    }
    setValue((prev) => [...prev, createOption(inputValue)]);
    onValueChange((prev: any) => [...prev, inputValue]);
    setInputValue("");
  };
  const handleInputChange = (newValue: any, actionMeta: any) => {
    if (
      actionMeta.action !== "input-blur" &&
      actionMeta.action !== "menu-close"
    ) {
      setInputValue(newValue);
    }
  };
  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (value.find((option) => option.label === inputValue)) {
          setInputValue("");
          return;
        }
        setValue((prev) => [...prev, createOption(inputValue)]);
        onValueChange((prev: any) => [...prev, inputValue]);
        setInputValue("");
        event.preventDefault();
    }
  };
  return (
    <>
      <label htmlFor={name}>{text}</label>
      <div className="input-field">
        <div className="creatable">
          <CreatableSelect
            id={name}
            components={components}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={(newValue) => {
              setValue(newValue);
              onValueChange(newValue.map((option) => option.label));
            }}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder || ""}
            value={value}
            required={required}
          />
        </div>
        <button
          type="button"
          onClick={handleClick}
          className="secondary_button w-[90px] h-[40px]"
        >
          + Add
        </button>
      </div>
    </>
  );
};
export default MultiSelectInput;
