import React, { useState } from "react";

import styles from "./beta.module.css";
import { observer } from "mobx-react";

interface IProps {
  show?: boolean;
  setShow: (prop: boolean) => void;
}

const Beta: React.FC<IProps> = ({ show, setShow }) => {
  return (
    <>
      {show && (
        <div className={styles.beta_container}>
          <p className="text-white text-sm font-inter font-semibold">
            Welcome to the ClassX Beta! We are constantly working on improving.
            Your feedback is appreciated.
          </p>
          <img
            src="/images/icons/close-white.svg"
            alt=""
            onClick={() => setShow(false)}
          />
        </div>
      )}
    </>
  );
};

export default observer(Beta);
