import { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import {
  CourseComponents,
  Footer,
  Navbar,
  Playlist,
} from "../../../components";
import { useStore } from "../../../store";

function Course() {
  const params = useParams();
  const { courseStore, userStore } = useStore();
  const course: any = courseStore.courseDetails;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (params.id) {
      courseStore.getCourseById(String(params.id));
    }
    userStore.getUser();
  }, []);

  return (
    <>
      <Navbar />

      <div className="w-full flex flex-col justify-start items-start space-y-2">
        <div className="w-full">
          <CourseComponents.CourseBreadcrumbs type="course" />
          <CourseComponents.CourseHeader type="course" />
          <CourseComponents.CourseStats />
        </div>
        <div className="w-full">
          <CourseComponents.CourseNavBar type="course" />
        </div>
        <div className="w-full">
          <CourseComponents.AboutCourse type="course" />
        </div>
        <div className="w-full">
          <CourseComponents.CourseContent />
        </div>
        <div className="w-full">
          {course?.tutors &&
          course?.tutors.fullName &&
          course?.tutors.fullName !== "wecodeland" ? (
            <CourseComponents.AboutInstructor type="course" />
          ) : null}
        </div>
        {courseStore.searchedCourses &&
        courseStore.searchedCourses.length > 0 ? (
          <div className="w-full h-screen">
            <CourseComponents.CourseUpcomingCohorts courseId={course?.id} />
          </div>
        ) : (
          <div className="w-full">
            <CourseComponents.CourseUpcomingCohorts courseId={course?.id} />
          </div>
        )}
        <div className="w-full">
          <CourseComponents.CourseStories type="course" />
        </div>
        <div className="w-full">
          {course?.objectifs?.image?.name ||
          course?.perspectives?.images?.name ? (
            <CourseComponents.CourseGoals type="course" />
          ) : null}
        </div>
        <div className="w-full">
          <CourseComponents.FAQs />
        </div>{" "}
        <div className="w-full">
          <Footer />
        </div>{" "}
      </div>
    </>
  );
}

export default observer(Course);
