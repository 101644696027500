import { createRef, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import styles from "./payment.module.css";
import { Footer, Navbar, PaymentComponents } from "../../../components";
import { OtherCourses } from "../../../components/others/courses";
import { useStore } from "../../../store";
import { observer } from "mobx-react";

function Payment() {
  const params = useParams();
  const navigate = useNavigate();
  const { courseStore, userStore } = useStore();

  const token = localStorage.getItem("token");

  const [agree, setAgree] = useState<boolean>(false);
  const [status, setStatus] = useState<1 | 2>(1);
  const [paymentMethod, setPaymentMethod] = useState<
    "PostWire" | "Onsite" | "OnlinePayment" | "KonnectPayment" | "StripePayment"
  >("Onsite");

  const submitRef = createRef<HTMLButtonElement>();

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    userStore.getUser();

    if (params.id) {
      courseStore.getTopRatedCourses();
      courseStore.getCohortById(params.id);
    }
  }, []);

  return (
    <div>
      <Navbar />

      <main>
        <div className={styles.payment_container}>
          <PaymentComponents.PaymentBreadcrumbs />

          <div className={styles.payment_content}>
            <PaymentComponents.PaymentForm
              agree={agree}
              status={status}
              setStatus={setStatus}
              submitRef={submitRef}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
            <PaymentComponents.PaymentDetails
              status={status}
              submitRef={submitRef}
              agree={agree}
              setAgree={setAgree}
              setStatus={setStatus}
              paymentMethod={paymentMethod}
            />
          </div>
        </div>

        <OtherCourses />
      </main>

      <Footer />
    </div>
  );
}

export default observer(Payment);
