import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../store";
import { Navbar } from "../../../components";
import "./styles.css";
import PersonalInfo from "./PersonalInfo";
import Privacy from "./Privacy";
import SocialMedia from "./SocialMedia";
import env from "../../../config/env";

export default function MyProfile() {
  const navigate = useNavigate();
  const { userStore } = useStore();

  const [loading, setLoading] = useState(true);

  const user = userStore.user;

  useEffect(() => {
    userStore.getUser().then(() => {
      const user = userStore.user;
      if (!user) {
        navigate("/login");
      }
      setLoading(false);
    });
  }, []);

  const [activeTab, setActiveTab] = useState("personalInfo");
  const personalInfoRef = useRef(null);
  const privacyRef = useRef(null);
  const socialMediaRef = useRef(null);

  const handleTabClick = (tab: string, ref: any) => {
    setActiveTab(tab);
    // ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  if (loading) {
    return (
      <div>
        <Navbar />
      </div>
    );
  }

  return (
    <div>
      <Navbar />

      <div className="container">
        <h1>My profile</h1>

        <div className="profile">
          <img
            src={
              user?.image && Object.keys(user?.image).length > 0
                ? `${env.IMAGE_API}${user?.image?.name}`
                : "images/default-user.svg"
            }
            alt="profile"
          />
          <div className="name">{user?.fullName}</div>
        </div>

        <div className="tabs">
          <div
            className={`tab ${activeTab === "personalInfo" ? "active" : ""}`}
            onClick={() => handleTabClick("personalInfo", personalInfoRef)}
          >
            Personal info
          </div>
          <div
            className={`tab ${activeTab === "privacy" ? "active" : ""}`}
            onClick={() => handleTabClick("privacy", privacyRef)}
          >
            Privacy
          </div>
          <div
            className={`tab ${activeTab === "socialMedia" ? "active" : ""}`}
            onClick={() => handleTabClick("socialMedia", socialMediaRef)}
          >
            Social media
          </div>
        </div>

        {activeTab === "personalInfo" && (
          <div ref={personalInfoRef} className="tab-content">
            <PersonalInfo />
          </div>
        )}

        {activeTab === "privacy" && (
          <div ref={privacyRef} className="tab-content">
            <Privacy user={user} />
          </div>
        )}

        {activeTab === "socialMedia" && (
          <div ref={socialMediaRef} className="tab-content">
            <SocialMedia />
          </div>
        )}
      </div>
    </div>
  );
}
