"use client";
import React, { useEffect, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";
import "./styles.css";
import "react-international-phone/style.css";
import { Input, Select, Button } from "../../../components";
import { MultiSelectInput, Navbar } from "../../../components/shared";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../store";
import { toast, ToastContainer } from "react-toastify";

function InstructorUpgrade() {
  const navigate = useNavigate();
  const { userStore, courseStore } = useStore();

  const [jobTitle, setJobTitile] = React.useState<string>("");
  const [skills, setSkills] = React.useState([]);
  const [socialLink, setSocialLink] = React.useState<string>("");
  const [socialApp, setSocialApp] = React.useState<string>("");
  const [bio, setBio] = React.useState<string>("");
  const [expertise, setExpertise] = React.useState([]);
  const [dragging, setDragging] = React.useState(false);
  const [profilePicture, setProfilePicture] = React.useState<File>();
  const pictureUploadRef = useRef<HTMLInputElement>(null);
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = React.useState<File[]>([]);

  const jobTitleList = [
    { value: 1, label: "University teacher" },
    { value: 2, label: "IT engineer" },
    { value: 3, label: "Web designer" },
    { value: 4, label: "Ui Ux designer" },
    { value: 5, label: "Project manager" },
    { value: 6, label: "Business manager" },
    { value: 6, label: "Freelance" },
    { value: 6, label: "Other" },
  ];
  const socialAppList = [
    { value: 1, label: "LinkedIn" },
    { value: 2, label: "Twitter" },
    { value: 3, label: "Facebook" },
  ];

  useEffect(() => {
    userStore.getUser().then(() => {
      const user = userStore.user;
      if (!user) {
        navigate("login");
      }
    });
  }, []);
  const handlePictureUpload = (): void => {
    if (pictureUploadRef.current && pictureUploadRef.current.files) {
      const file = pictureUploadRef.current.files[0];
      setProfilePicture(file);
    }
  };
  const triggerFileInput = (): void => {
    pictureUploadRef.current?.click();
  };
  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };
  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };
  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      setProfilePicture(file);
    }
  };

  const onRequestUpgrade = async () => {
    try {
      const response = await userStore.sendUpgradeRequest(
        "teacher",
        userStore.user.id
      );
      response.STATUS === 200
        ? toast.success(response.MESSAGE)
        : toast.error(response.MESSAGE);
      setTimeout(() => {
        navigate("/");
      }, 5000);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleAttachFile = (e: any) => {
    const newFiles: File[] = Array.from(e.target.files);
    const existingFileNames = files.map((file) => file.name);
    const filteredNewFiles = newFiles.filter(
      (file: File) => !existingFileNames.includes(file.name)
    );
    setFiles([...files, ...filteredNewFiles]);
  };
  const triggerAttachFile = (): void => {
    fileUploadRef.current?.click();
  };
  const handleSave = () => {
    console.log(
      profilePicture,
      jobTitle,
      skills,
      socialLink,
      socialApp,
      bio,
      expertise,
      files
    );
  };

  return (
    <div className="main">
      <ToastContainer theme="dark" />
      <Navbar isTransparent />
      <h1 className="title">Instructor Upgrade</h1>
      <form>
        <div className="inputs">
          <div className="left-column">
            <div className="field">
              <label htmlFor="picture">Profile Picture</label>
              <div className="input-field">
                <div
                  className="upload-picture"
                  style={{
                    backgroundColor: dragging ? "lightblue" : "#f5f5f5",
                  }}
                  onClick={triggerFileInput}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onDragOver={handleDragEnter}
                  onDrop={handleDrop}
                >
                  <div className="default-picture">
                    {dragging ? (
                      <div>Drop Your Picture Here</div>
                    ) : profilePicture ? (
                      <img src={URL.createObjectURL(profilePicture)} alt="" />
                    ) : (
                      <>
                        <div>+</div>
                        <div>Upload</div>
                      </>
                    )}
                  </div>
                </div>
                <input
                  className="fileInput"
                  ref={pictureUploadRef}
                  type="file"
                  id="picture"
                  onChange={handlePictureUpload}
                />
              </div>
            </div>
            <div className="field">
              <label htmlFor="job-title">Job Title</label>
              <div className="input-field">
                <Select
                  label="Job Title"
                  value={jobTitle}
                  options={jobTitleList}
                  onChange={(e) => setJobTitile(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <MultiSelectInput
                text="Skils: life and soft skills"
                name="skills"
                onValueChange={setSkills}
              />
            </div>
            <div className="field">
              <label htmlFor="social">Social Media (optional)</label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="text"
                    label="Insert Link"
                    fullWidth
                    value={socialLink}
                    onChange={(e) => setSocialLink(e.target.value)}
                  />
                </div>
                <div className="select">
                  <Select
                    label="Website"
                    value={socialApp}
                    options={socialAppList}
                    onChange={(e) => setSocialApp(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="right-column">
            <div className="field">
              <label htmlFor="bio">Bio</label>
              <div className="input-field">
                <textarea
                  className="bio"
                  id="bio"
                  placeholder="Tell us about yourself"
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <MultiSelectInput
                text="Expertise"
                name="expertise"
                onValueChange={setExpertise}
              />
            </div>

            <div className="field">
              <label>Attachments: certifications, diplomas, etc...</label>
              <div className="input-field" id="files">
                <div className="files">
                  {files.map((file, index) => (
                    <div className="file" key={index}>
                      <img
                        className="document"
                        src="/images/document.png"
                        alt="doc"
                      />
                      <p>{file.name}</p>
                      <img
                        className="trash"
                        src="/images/trash.png"
                        alt="trash"
                        onClick={() =>
                          setFiles(files.filter((f, i) => i !== index))
                        }
                      />
                    </div>
                  ))}
                </div>
                <input
                  className="fileInput"
                  type="file"
                  ref={fileUploadRef}
                  multiple
                  onChange={(e) => handleAttachFile(e)}
                />
                <Button
                  text="+ Attach file"
                  type="secondary"
                  width="200px"
                  customColor="rgb(117, 116, 116)"
                  onClick={triggerAttachFile}
                  preventDefault
                />
              </div>
            </div>
          </div>
        </div>

        <div className="buttons">
          <Button
            text="Cancel"
            type="primary"
            customColor="#373737"
            width="400px"
            preventDefault={true}
            onClick={() => navigate(-1)}
          />
          <Button
            text="Save"
            type="primary"
            width="400px"
            preventDefault={true}
            onClick={onRequestUpgrade}
          />
        </div>
      </form>
    </div>
  );
}
export default observer(InstructorUpgrade);
