import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../../../components";
import env from "../../../../config/env";

interface ClassroomProps {
  id: string;
  image: any;
  name: string;
  capacity: number;
  description: string;
  location: any;
  from: Date;
  to: Date;
  requested: boolean;
  handleRequest: (id: string) => void;
}

export default function Classroom({
  id,
  image,
  name,
  capacity,
  description,
  location,
  from,
  to,
  requested,
  handleRequest,
}: ClassroomProps) {
  const navigate = useNavigate();

  const handleDate = (date: Date) => {
    return date ? date.toString().substring(0, 10).replace(/-/g, "/") : "";
  };

  const handleClick = () => {
    handleRequest(id);
  };

  return (
    <div className="classroom">
      <div className="image-container">
        <img
          className="image"
          src={
            image?.name
              ? `${env.IMAGE_API}${image.name}`
              : "/images/course-default.png"
          }
          alt="classroom-image"
        />
      </div>
      <div className="header">
        <Link to={`/classroom/${id}`} target="blank">
          <h2>{name}</h2>
        </Link>
        <span>{capacity} Persons max</span>
      </div>
      <p>{description}</p>
      <div className="location">
        <img src="/images/icons/location-gray.svg" alt="" />
        <p>{location?.name}</p>
      </div>
      <div className="location">
        <img src="/images/icons/date.svg" alt="" />
        <p>
          {handleDate(from)} To {handleDate(to)}
        </p>
      </div>
      {requested ? (
        <Button type="secondary" text="Requested" onClick={handleClick} />
      ) : (
        <Button
          type="primary"
          text="Request this classroom"
          onClick={handleClick}
        />
      )}
    </div>
  );
}
