import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../../components";
import env from "../../../config/env";
import { useStore } from "../../../store";
import { useEffect, useState } from "react";
import { addFavorite, deleteFavorite } from "src/store/session";

interface ItemProps {
  id: string;
  image: any;
  name: string;
  capacity: number;
  description: string;
  location: any;
  type: string;
  price?: number;
  tutor?: string;
  start?: any;
  end?: any;
  domains?: string[];
  rating?: number;
  status?: string;
  ratingsCount?: number;
  discount?: number;
}

export default function Item({
  id,
  image,
  name,
  capacity,
  description,
  location,
  type,
  price,
  tutor,
  start,
  end,
  domains,
  rating,
  status,
  ratingsCount,
  discount,
}: ItemProps) {
  const navigate = useNavigate();
  const { courseStore, userStore } = useStore();
  
  const [liked, setLiked] = useState(true);
  const [user, setUser] = useState<any | null>(null);

  useEffect(() => {
    userStore.getUser().then(() => {
      const user = userStore.user;
      if (!user) {
        navigate("/login");
      } else {
        setUser(user);
      }
    });
  }, [liked]);

  const handleDelete = async () => {
    const url = type === "cohort" ? "/FavoriteSessions" : "/FavoriteCourses";
    if (liked) {
      const favorites =
        type === "cohort" ? user?.favoriteSession : user?.favoriteCourse;
      const favId = favorites?.find(
        (fav: any) =>
          fav.profileId === user?.id &&
          (type === "cohort" ? fav.sessionId === id : fav.courseId === id)
      )?.id;
      const res = await deleteFavorite(url, favId);
      if (res) setLiked(false);
    } else {
      const body =
        type === "cohort"
          ? {
              profileId: user?.id,
              sessionId: id,
            }
          : {
              profileId: user?.id,
              courseId: id,
            };
      const res = await addFavorite(url, body);
      if (res) setLiked(true);
    }
  };

  const handleDate = (date: Date) => {
    return date ? date.toString().substring(0, 10).replace(/-/g, "/") : "";
  };

  const participate = async () => {
    courseStore.selectCourse(id);
    if (userStore.user) {
      navigate(`/payment/${id}`);
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="item">
      <div className="image-container">
        {discount !== 0 && (
          <div className="discount">
            <span>{`-${discount} % off`}</span>
          </div>
        )}
        <div className="icon" onClick={handleDelete}>
          <img
            src={`/images/icons/favorite${liked ? "-filled" : ""}.svg`}
            alt="delete"
          />
        </div>
        <img
          className="image"
          src={
            image && Object.keys(image).length > 0
              ? `${env.IMAGE_API}${image?.name}`
              : "images/course-default.png"
          }
          alt="image"
        />
      </div>
      {type === "cohort" && (
        <div className="header">
          <div className="price">{price}</div>
          <span>{capacity} places left</span>
        </div>
      )}
      <Link to={`/${type}/${id}`} target="_blank">
        <h2>{name}</h2>
      </Link>

      {type === "course" && (
        <div className="rating">
          <span>{rating}</span>
          <img
            width={24}
            height={24}
            alt="Star Icon"
            src="/images/icons/star.svg"
          />
          <span>({ratingsCount} ratings)</span>
        </div>
      )}

      {type === "cohort" && (
        <div className="tutor">
          With <span>{tutor}</span>
        </div>
      )}

      {type === "cohort" && (
        <>
          <div className="location">
            <img src="/images/icons/location-gray.svg" alt="" />
            <p>{location?.name}</p>
          </div>
          <div className="location">
            <img src="/images/icons/date.svg" alt="" />
            <p>
              {handleDate(start)} To {handleDate(end)}
            </p>
          </div>
        </>
      )}

      <p>{description}</p>

      <div className="domains">
        {domains &&
          domains.map((domain: any, index: number) => (
            <div className="domain" key={index}>
              {type === "cohort" ? domain : domain.name}
            </div>
          ))}
      </div>

      {type === "course" ? (
        <Link to={`/${type}/${id}`} target="_blank">
          <Button type="primary" text="Explore" />
        </Link>
      ) : status === "Completed" ? (
        <Button type="primary" text="Completed" isDisabled />
      ) : (
        <Button type="primary" text="Participate" onClick={participate} />
      )}
    </div>
  );
}
