import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

import styles from "./header.module.css";
import { Button, CourseComponents, Popup } from "../../../../components";
import { useStore } from "../../../../store";
import env from "../../../../config/env";
import { useTranslation } from "react-i18next";

interface IProps {
  type: "course" | "cohort";
}

function CourseHeader({ type }: IProps) {
  const navigate = useNavigate();
  const { courseStore, userStore } = useStore();
  const { t } = useTranslation();
  const [active, setActive] = useState<number>(1);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isScheduleOpen, setIsScheduleOpen] = useState<boolean>(false);
  const [isAddedToFavorites, setIsAddedToFavorites] = useState<boolean>(false);
  const [isWishListClicked, setIsWishListClicked] = useState<boolean>(false);
  let course: any = courseStore.courseDetails;
  const image =
    type === "cohort" ? course?.SessionImage?.name : course?.image?.name;
  const endTime = course?.endTime
    ? new Date(course.endTime).toLocaleDateString()
    : null;
  const startTime = course?.startTime
    ? new Date(course.startTime).toLocaleDateString()
    : null;

  useEffect(() => {
    if (userStore.user) {
      let user = userStore.user;
      let userFavoritesList: any = [];
      if (user.favoriteCourse && user.favoriteCourse.length > 0) {
        userFavoritesList.push(...user.favoriteCourse);
      }
      if (user.favoriteSession && user.favoriteSession.length > 0) {
        userFavoritesList.push(...user.favoriteSession);
      }
      let userFavoritesSessionIds = userFavoritesList.map(
        (userFavorite: any) => {
          return userFavorite.sessionId
            ? userFavorite.sessionId.toString()
            : userFavorite.courseId.toString();
        }
      );

      if (userFavoritesSessionIds && userFavoritesSessionIds.length > 0) {
        if (userFavoritesSessionIds.includes(course?.id)) {
          setIsAddedToFavorites(true);
        }
      }
    }
  }, [userStore.user, course]);

  const lookForSessions = async () => {
    if (type === "course") {
      onClick(8, "upcoming-cohorts");
    } else {
      courseStore.selectCourse(course.id);

      if (userStore.user) {
        //await courseStore.lookForSessions(course.id);
        navigate("/search");
      } else {
        setIsOpen(true);
      }
    }
  };

  const onClick = (index: number, link: string) => {
    setActive(index);
    const element = document.getElementById(link);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const addToFavorites = async () => {
    setIsWishListClicked(true);
    if (userStore.user) {
      let response: any;
      type === "cohort"
        ? (response = await courseStore.addSessionToFavorites(
            course?.id,
            userStore.user.id
          ))
        : (response = await courseStore.addCourseToFavorites(
            course?.id,
            userStore.user.id
          ));
      await userStore.getUser().then(() => {
        setIsAddedToFavorites(true);
        setIsWishListClicked(false);
      });
    } else {
      navigate("/login");
    }
  };

  const deleteFromFavorites = async () => {
    setIsWishListClicked(true);
    if (userStore.user) {
      let favoriteObject =
        type === "cohort"
          ? userStore.user.favoriteSession.find(
              (favoriteSession: any) =>
                favoriteSession.sessionId.toString() === course.id.toString()
            )
          : userStore.user.favoriteCourse.find(
              (favoriteCourse: any) =>
                favoriteCourse.courseId.toString() === course.id.toString()
            );
      type === "cohort"
        ? await courseStore.deleteSessionFromFavorites(favoriteObject.id)
        : await courseStore.deleteCourseFromFavorites(favoriteObject.id);
      await userStore.getUser().then(() => {
        setIsAddedToFavorites(false);
        setIsWishListClicked(false);
      });
    } else {
      navigate("/login");
    }
  };

  const participate = async () => {
    courseStore.selectCourse(course.id);

    if (userStore.user) {
      navigate(`/payment/${course.id}`);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div className={styles.course_header}>
      <div className={styles.course_header_content}>
        <div className={styles.course_header_description}>
          {course?.domains?.length > 0 && (
            <div className={styles.tag}>
              <span>{course?.domains[0]?.name ?? course.domains[0]}</span>
            </div>
          )}

          <h1>{course?.title}</h1>
          <p>{course?.description}</p>

          <span className={styles.priceText}>{course?.price}</span>

          <div className={styles.course_header_details}>
            <div className={styles.course_header_details_item}>
              <img
                width={24}
                height={24}
                alt="Star Icon"
                src="/images/icons/star.svg"
              />
              <span style={{ fontWeight: "600" }}>{course?.rating || 5}</span>
              <span style={{ textDecoration: "underline" }}>
                ({course?.ratingsCount || 5} {t("messages.review")})
              </span>
              {!(
                type === "course" &&
                (course?.courseCreator === "wecodeland" ||
                  course?.courseCreator === "WeCode")
              ) &&
                !(
                  type !== "course" &&
                  (course?.sessionCourse?.courseCreator === "wecodeland" ||
                    course?.sessionCourse?.courseCreator === "WeCode")
                ) && (
                  <span>
                    • {t("messages.createdBy")}{" "}
                    <span className={styles.creatorText}>
                      {type === "course"
                        ? course?.courseCreator
                        : course?.sessionCourse?.courseCreator}
                    </span>
                  </span>
                )}
            </div>
          </div>

          <div className="w-full flex justify-start items-center space-x-4 pt-8">
            <div className="w-auto">
              <Button
                text={t("buttons.wishlist")}
                type="secondary"
                icon={
                  isAddedToFavorites
                    ? "/images/icons/heart-filled.svg"
                    : "/images/icons/heart.svg"
                }
                onClick={() =>
                  isAddedToFavorites ? deleteFromFavorites() : addToFavorites()
                }
                isWishListClicked={isWishListClicked}
              />
            </div>
            {type === "course" && (
              <div className="w-auto">
                <Button
                  type="primary"
                  text={t("buttons.viewUpcomingCohorts")}
                  onClick={() => lookForSessions()}
                />
              </div>
            )}
            {type === "cohort" && (
              <Button
                type="primary"
                text={t("buttons.participate")}
                width="170px"
                onClick={() => participate()}
              />
            )}
          </div>

          <div className={styles.date_and_location}>
            {type === "cohort" &&
            (course?.isOnline || course?.sessionBooking?.length > 0) ? (
              <div className={styles.course_header_details}>
                <img
                  width={24}
                  height={24}
                  alt="Star Icon"
                  src="/images/icons/location-gray.svg"
                />
                <span>
                  {course?.isOnline
                    ? "Online"
                    : course?.sessionBooking[0]?.bookingRoom?.address?.name}
                </span>
              </div>
            ) : null}
            {type === "cohort" && startTime && endTime ? (
              <div
                className={styles.course_schedule_details}
                onClick={() => setIsScheduleOpen(true)}
              >
                <img
                  width={24}
                  height={24}
                  alt="Star Icon"
                  src="/images/icons/date.svg"
                />
                <span>
                  {startTime} To {endTime}
                </span>
                <img
                  className={styles.info_icon}
                  width={24}
                  height={24}
                  src="/images/icons/info.svg"
                  alt="info"
                />
              </div>
            ) : null}
            {type === "cohort" && course?.subscribedNumber ? (
              <div className={styles.course_schedule_details}>
                <img
                  width={24}
                  height={24}
                  alt="Star Icon"
                  src="/images/icons/people.svg"
                />
                <span>
                  {t("messages.groupOf")} {course?.subscribedNumber}{" "}
                  {t("messages.person")}
                </span>
              </div>
            ) : null}
          </div>
        </div>

        <img
          alt={course?.title}
          src={
            image ? `${env.IMAGE_API}${image}` : "/images/course-default.png"
          }
          className={styles.course_header_image}
        />
      </div>

      {isOpen && (
        <Popup
          type={type}
          isOpen={isOpen}
          course={course.id}
          closeModal={() => setIsOpen(false)}
        />
      )}

      {isScheduleOpen && (
        <Modal
          isOpen={isScheduleOpen}
          onRequestClose={() => setIsScheduleOpen(false)}
          style={customStyles}
        >
          <CourseComponents.CourseSchedule />
          <Button
            text="Ok"
            type="primary"
            width="100px"
            customStyle={{ marginLeft: "auto" }}
            onClick={() => setIsScheduleOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "80%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    borderRadius: "8px",
    border: "1px solid #ccc",
    transform: "translate(-50%, -50%)",
  },
};

export default observer(CourseHeader);
