import React from "react";

import styles from "./course.module.css";
import env from "../../../../config/env";
import { useStore } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { formatDateTimeRange } from "../../../../utils/dateFormat";

interface IProps {
    type?: "course" | "cohort";
    course: {
        id: string;
        title: string;
        category: string;
        description: string;
        image: string;
        price: number;
        rating: number;
        ratingCount: number;
        startTime?: string;
        endTime?: string;
        location?: string;
        provider: {
            name: string;
            logo: string;
        };
    };
}

const Item: React.FC<IProps> = ({ course, type }) => {
    const navigate = useNavigate();
    const { courseStore } = useStore();
    
    const starts = new Array(Math.ceil(course.rating)).fill(null);
    const endTime = course.endTime;
    const startTime = course.startTime;

    let dateTime;

    if (startTime && endTime) {
        dateTime = formatDateTimeRange(startTime, endTime);
    }
    
    const onClick = () => {
        if (type === "cohort") {
            courseStore.getCohortById(course.id);
            return navigate(
                `/cohort/${course.id}`
            );
        } else {
            courseStore.getCourseById(course.id);
            navigate(
                `/course/${course.id}`
            );
        }
    }

    return (
        <div
            className={styles.course_container}
            onClick={onClick}
        >
            <img className={styles.picture} src={`${env.IMAGE_API}${course.image}`} alt={course.title} />

            <div className={styles.course_details}>
                <div>
                    <div className={styles.course_titles}>
                        <span className={styles.course_category}>{course.category}</span>
                        <span className={styles.course_title}>{course.title}</span>
                        <p className={styles.course_description}>{course.description}</p>
                    </div>

                    <div className={styles.course_details_items}>
                        {type=== "course" ? (
                            <div className={styles.course_details_block}>
                                <span>{course.rating}</span>
                                <div className={styles.rating_text}>
                                    {starts.map((_, idx) => (
                                        <img
                                            key={idx}
                                            width={16}
                                            height={16}
                                            src="/images/icons/star.svg"
                                            alt="star"
                                        />
                                    ))}
                                </div>
                                <span className={styles.rating_count_text}>({course.ratingCount})</span>
                            </div>
                        ) : null}

                        {type === "cohort" && course.location ? (
                            <div className={styles.course_details_block}>
                                <img
                                    width={16}
                                    height={16}
                                    src="/images/icons/location-gray.svg"
                                    alt="date"
                                />
                                <span className={styles.rating_count_text}>{course.location}</span>
                            </div>
                        ) : null}

                        {course.startTime && course.endTime ? (
                            <div className={styles.course_details_block}>
                                <img
                                    width={16}
                                    height={16}
                                    src="/images/icons/date.svg"
                                    alt="date"
                                />
                                <span className={styles.rating_count_text}>
                                    {dateTime}
                                </span>
                            </div>
                        ) : null}
                    </div>
                </div>

                {type === "cohort" ? (
                    <div className={styles.course_footer}>
                        {/* <div className={styles.course_provider}>
                            <img
                                width={40}
                                height={40}
                                src={course.provider.logo}
                                alt={course.provider.name}
                                style={{ borderRadius: "50%" }}
                            />
                            <span>{course.provider.name}</span>
                        </div> */}
                        <span className={styles.course_price}>{course.price}</span>
                    </div>
                ): null}
            </div>
        </div>
    );
};

export default Item;