import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../../../store";
import {
  Navbar,
  Loading,
  UploadImage,
  Input,
  TextEditor,
  Select as RSelect,
  MultiSelectInput,
  Button,
} from "src/components/shared";
import Select from "react-select";
import "./styles.css";

export default function NewCourse() {
  const navigate = useNavigate();
  const { userStore, courseStore } = useStore();

  useEffect(() => {
    userStore.getUser().then(() => {
      const user = userStore.user;
      if (!user) {
        navigate("/login");
      }
    });
  }, []);

  const user = userStore.user;

  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState<File | null>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [syllabus, setSyllabus] = useState("");
  const [forWho, setForWho] = useState("");
  const [objectifs, setObjectifs] = useState("");
  const [objectivesImage, setObjectivesImage] = useState<File | null>(null);
  const [perspectives, setPerspectives] = useState("");
  const [perspectivesImage, setPerspectivesImage] = useState<File | null>(null);
  const [price, setPrice] = useState("");
  const [level, setLevel] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [skills, setSkills] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [domains, setDomains] = useState([]);
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState<
    { value: string; label: string }[]
  >([]);

  const courseLevels = [
    { value: 1, label: "Beginner" },
    { value: 2, label: "Intermediate" },
    { value: 3, label: "Advanced" },
  ];

  useEffect(() => {
    setLoading(true);
    Promise.all([courseStore.getAllDomains(), courseStore.getAllCategories()])
      .then(([domainsResponse, categoriesResponse]) => {
        if (domainsResponse !== null) {
          const domains = domainsResponse.map((domain: any) => ({
            value: domain.id,
            label: domain.name,
          }));
          setDomains(domains);
        }

        if (categoriesResponse !== null) {
          console.log(categoriesResponse);
          const categories = categoriesResponse.map((category: any) => ({
            value: category.id,
            label: category.name,
          }));
          setCategories(categories);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const links = [{ path: "/mycourses", label: "My Courses" }];

  const handleSave = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const course = {
      title: title,
      description: description,
      image: image,
      difficulty: level,
      courseCreator: createdBy,
      forWho: forWho,
      price: price,
      syllabus: syllabus,
      prerequisite: skills,
      objectifs: {
        content: objectifs,
        image: objectivesImage,
      },
      perspectives: {
        content: perspectives,
        image: perspectivesImage,
      },
      domains: selectedDomains.map((domain: any) => {
        return { id: domain.value, name: domain.label };
      }),
      categoryId: categories.find((cat: any) => cat.label === category)?.value,
    };

    if (image) {
      await userStore.uploadPicture(image).then((response) => {
        course.image = response;
      });
    }

    if (perspectivesImage) {
      await userStore.uploadPicture(perspectivesImage).then((response) => {
        course.perspectives.image = response;
      });
    }

    if (objectivesImage) {
      await userStore.uploadPicture(objectivesImage).then((response) => {
        course.objectifs.image = response;
      });
    }

    await courseStore
      .newCourse(course)
      .then((response) => {
        console.log(course);
        console.log(response);
        setLoading(false);
        navigate("/mycourses");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />

      <form className="new-course" onSubmit={handleSave}>
        <div className="links">
          {links.map((link, index) => (
            <div className="link" key={index}>
              <Link to={link.path} className="label">
                {link.label}
              </Link>
              <div className="arrow">&gt;</div>
            </div>
          ))}
          <div className="active label">Add new course</div>
        </div>

        <h1>Add new course</h1>

        <div className="inputs">
          <div className="left-column">
            <div className="field">
              <label htmlFor="picture">Course banner</label>
              <div className="input-field">
                <UploadImage image={image} setImage={setImage} width="80%" />
              </div>
            </div>
          </div>

          <div className="right-column">
            <div className="field">
              <label htmlFor="title">Title</label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="text"
                    label="Title"
                    required
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="description">Description</label>
              <div className="input-field">
                <textarea
                  className="bio"
                  required
                  id="description"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          <label htmlFor="syllabus">Syllabus</label>
          <div className="input-field">
            <TextEditor text={syllabus} setText={setSyllabus} readOnly={false} />
          </div>
        </div>

        <div className="field">
          <label htmlFor="forWho">For who</label>
          <div className="input-field">
            <textarea
              className="bio"
              id="forWho"
              placeholder="For who"
              value={forWho}
              onChange={(e) => setForWho(e.target.value)}
            />
          </div>
        </div>

        <div className="objectives">
          <div className="left-column">
            <div className="field">
              <label htmlFor="objectifs">Objectifs</label>
              <div className="input-field">
                <TextEditor text={objectifs} setText={setObjectifs} readOnly={false}/>
              </div>
            </div>
          </div>

          <div className="right-column">
            <div className="field">
              <div className="input-field">
                <UploadImage
                  image={objectivesImage}
                  setImage={setObjectivesImage}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="objectives">
          <div className="left-column">
            <div className="field">
              <label htmlFor="perspectives">Perspectives</label>
              <div className="input-field">
                <TextEditor text={perspectives} setText={setPerspectives} readOnly={false}/>
              </div>
            </div>
          </div>

          <div className="right-column">
            <div className="field">
              <div className="input-field">
                <UploadImage
                  image={perspectivesImage}
                  setImage={setPerspectivesImage}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="inputs">
          <div className="left-column">
            <div className="field">
              <label htmlFor="price">Price per person</label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="number"
                    fullWidth
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="level">Course level</label>
              <div className="input-field">
                <div className="input">
                  <RSelect
                    label="Course level"
                    fullWidth
                    options={courseLevels}
                    value={level}
                    onChange={(e) => setLevel(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="createdBy">Created by (optional)</label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="text"
                    fullWidth
                    value={createdBy}
                    onChange={(e) => setCreatedBy(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="right-column">
            <div className="field">
              <MultiSelectInput
                text="Required skills"
                name="skills"
                onValueChange={setSkills}
                required
              />
            </div>

            <div className="field">
              <label htmlFor="domain">Domain</label>
              <div className="input-field">
                <div className="input">
                  <Select
                    isMulti
                    options={domains}
                    value={selectedDomains}
                    closeMenuOnSelect={false}
                    onChange={(e: any) => setSelectedDomains(e)}
                    placeholder="Select domain(s)"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="category">Category</label>
              <div className="input-field">
                <div className="input">
                  <RSelect
                    label="Select category"
                    fullWidth
                    options={categories}
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="buttons">
          <Button
            text="Cancel"
            type="primary"
            customColor="#373737"
            width="400px"
            preventDefault={true}
            onClick={() => navigate(-1)}
          />
          <Button text="Save" type="primary" width="400px" submit />
        </div>
      </form>
    </>
  );
}
