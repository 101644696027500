import Item from "./Item";
import "./styles.css";

interface ItemsProps {
  data: any[] | null;
  page: number;
  numberOfPages: number;
  count: number;
  type: string;
  handlePreviousPage: () => void;
  handleNextPage: () => void;
}

export default function Items({
  data,
  page,
  count,
  numberOfPages,
  type,
  handleNextPage,
  handlePreviousPage,
}: ItemsProps) {
  return (
    <div className="tab-content">
      <div className="favorite-items">
        {data && data.length > 0 ? (
          data.map((item, index) => (
            <Item
              key={index}
              id={item?.id}
              type={type}
              price={item?.price}
              tutor={item?.tutors?.fullName}
              start={item?.start}
              end={item?.end}
              rating={item?.rating}
              ratingsCount={item?.ratingsCount}
              image={type === "course" ? item?.image : item?.SessionImage}
              name={item?.title}
              capacity={item?.nbrStillAvailable}
              description={item?.description}
              location={item?.address}
              status={item?.status}
              domains={item?.domains}
              discount={item?.discount}
            />
          ))
        ) : (
          <div className="nodata">
            <img src="/images/icons/nodata.svg" alt="" />
            <span>Oups! No {type}s found!</span>
          </div>
        )}
      </div>

      <div className="pagination">
        <span>
          {page} - {numberOfPages} of {count}
        </span>
        <button onClick={handlePreviousPage}>&lt;</button>
        <button onClick={handleNextPage}>&gt;</button>
      </div>
    </div>
  );
}
