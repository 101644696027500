import { Button } from "src/components/shared";
import Classroom from "./classroom";
import "./styles.css";

interface ClassroomsListProps {
  data: any[] | null;
  page: number;
  numberOfPages: number;
  count: number;
  requestedClassrooms: any[];
  handlePreviousPage: () => void;
  handleNextPage: () => void;
  setClassroomSelection: (value: boolean) => void;
  handleSave: () => void;
  setRequestedClassrooms: (value: any) => void;
}

export default function ClassroomsList({
  data,
  page,
  count,
  numberOfPages,
  requestedClassrooms,
  handleNextPage,
  handlePreviousPage,
  setClassroomSelection,
  handleSave,
  setRequestedClassrooms,
}: ClassroomsListProps) {
  const handleRequest = (id: string) => {
    console.log(requestedClassrooms)
    if (requestedClassrooms.find((item) => item.id === id)) {
      setRequestedClassrooms(requestedClassrooms.filter((item) => item.id !== id));
    } else {
      setRequestedClassrooms([...requestedClassrooms, {id}]);
    }
  };

  return (
    <div className="tab-content">
      <div className="classrooms">
        {data && data.length > 0 ? (
          data.map((classroom, index) => (
            <Classroom
              key={index}
              id={classroom.id}
              image={classroom.logo}
              name={classroom.name}
              capacity={classroom.size}
              description={classroom.description}
              location={classroom.address}
              from={classroom.from}
              to={classroom.to}
              requested={requestedClassrooms.find((item) => item.id === classroom.id)}
              handleRequest={handleRequest}
            />
          ))
        ) : (
          <div className="nodata">
            <img src="/images/icons/nodata.svg" alt="" />
            <span>Oups! No Classrooms Found!</span>
          </div>
        )}
      </div>

      <div className="pagination">
        <span>
          {page} - {numberOfPages} of {count}
        </span>
        <button onClick={handlePreviousPage}>&lt;</button>
        <button onClick={handleNextPage}>&gt;</button>
      </div>

      <div className="buttons">
        <Button
          text="Cancel"
          type="primary"
          customColor="#373737"
          width="400px"
          preventDefault={true}
          onClick={() => setClassroomSelection(false)}
        />
        <Button
          text="Save"
          type="primary"
          width="400px"
          preventDefault={true}
          onClick={handleSave}
        />
      </div>
    </div>
  );
}
