import styles from "./faqs.module.css";
import FaqItem from "./FaqItem";
import { faqs } from "../../../../constants";
import { useTranslation } from "react-i18next";

export default function Faqs() {
  const { t } = useTranslation();

  const faqs:any = t("faqs", { returnObjects: true });

  return (
    <div className={styles.course_faqs} id="faqs">
      <div className={styles.course_faqs_content}>
        <div className={styles.course_faqs_info}>
          <h4 className={styles.course_faqs_subtitle}>{t("buttons.faqs")}</h4>
          <h3 className={styles.course_faqs_title}>
            {t("messages.faqsDoubts")}
          </h3>
        </div>

        <div className={styles.course_faqs_items}>
          {faqs.map((faq:any, index:any) => (
            <FaqItem key={index} faq={faq} />
          ))}
        </div>
      </div>
    </div>
  );
}
