import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../store";
import {
  Navbar,
  Loading,
  UploadImage,
  Input,
  TextEditor,
  Select as RSelect,
  Button,
  DateRange,
  TimeRange,
} from "src/components/shared";
import Select from "react-select";
import { Switch } from "antd";
import { getAllSessionsAndCourses, getSessionDetails } from "src/store/session";
import Popup from "./Popup";
import ScheduleSelector from "react-schedule-selector";
import "../NewCourse/styles.css";
import ClassroomSelection from "./ClassroomSelection";

export default function NewSession() {
  const navigate = useNavigate();
  const { type, id } = useParams();
  const { userStore, courseStore } = useStore();

  useEffect(() => {
    userStore.getUser().then(() => {
      const user = userStore.user;
      if (!user) {
        navigate("/login");
      }
    });

    setLoading(true);

    Promise.all([
      courseStore.getAllDomains(),
      courseStore.getAllLanguages(),
      getAllSessionsAndCourses(),
      getSessionDetails(id),
    ])
      .then(
        ([
          domainsResponse,
          languagesResponse,
          sessionsAndCoursesResponse,
          sessionDetailsResponse,
        ]) => {
          if (domainsResponse !== null) {
            const domains = domainsResponse.map((domain: any) => ({
              value: domain.id,
              label: domain.name,
            }));
            setDomains(domains);
            setAllDomains(domainsResponse);
          }

          if (languagesResponse !== null) {
            const languages = languagesResponse.map((domain: any) => ({
              value: domain.id,
              label: domain.name,
            }));
            setLanguages(languages);
          }

          if (sessionsAndCoursesResponse !== null) {
            setSessions(sessionsAndCoursesResponse.sessions);
            setCourses(sessionsAndCoursesResponse.courses);
          }

          if (sessionDetailsResponse !== null) {
            setSessionDetails(sessionDetailsResponse);
          }

          setLoading(false);
          setFirstUseEffect(false);
        }
      )
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const user = userStore.user;

  const [firstUseEffect, setFirstUseEffect] = useState<boolean>(true);

  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [onlineSessionLink, setOnlineSessionLink] = useState("");
  const [syllabus, setSyllabus] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(undefined);
  const [endDate, setEndDate] = useState<any>(undefined);
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [schedule, setSchedule] = useState("");
  const [minPlace, setMinPlace] = useState("");
  const [totalDuration, setTotalDuration] = useState("");
  const [maxPlace, setMaxPlace] = useState("");
  const [discount, setDiscount] = useState("");
  const [TimeWindowForCancel, setTimeWindowForCancel] = useState("");
  const [price, setPrice] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [domains, setDomains] = useState([]);
  const [isOnline, setIsOnline] = useState<boolean>();
  const [languages, setLanguages] = useState<any>([]);
  const [language, setLanguage] = useState("");
  const [sessions, setSessions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [courseId, setCourseId] = useState<any>(null);
  const [sessionId, setSessionId] = useState<any>(null);
  const [scheduleData, setScheduleData] = useState<any>({
    schedule: [],
    hourlyChunks: 1,
    numDays: 7,
    minTime: 8,
    maxTime: 18,
    startDate: startDate || new Date().toISOString(),
  });
  const [classroomSelection, setClassroomSelection] = useState(false);
  const [newSessionData, setNewSessionData] = useState<any>();
  const [inCourse, setInCourse] = useState(false);
  const [allDomains, setAllDomains] = useState<any>([]);
  const [sessionDetails, setSessionDetails] = useState<any>();

  const difficulties = [
    { value: 1, label: "Beginner" },
    { value: 2, label: "Intermediate" },
    { value: 3, label: "Advanced" },
  ];

  const schedules = [
    { value: "morning", label: "Morning" },
    { value: "afternoon", label: "Afternoon" },
    { value: "evening", label: "Evening" },
  ];

  const handleTime = (date: string) => {
    return date ? date.substr(11, 2) : null;
  };

  useEffect(() => {
    console.log("sessionDetails", sessionDetails);
    const session: any =
      type === "edit"
        ? sessionDetails
        : sessions.find((session: any) => session.id === id);
    const course: any = courses.find((course: any) => course.id === id);

    if (type === "new" && (id === undefined || (!session && !course))) {
      setIsPopupOpen(true);
    } else if ((type === "edit" || type === "new") && id) {
      setIsPopupOpen(false);

      if (session) {
        setCourseId(session?.courseId);
        setIsOnline(session?.isOnline);
        setSessionId(session?.id);
        setImage(session?.SessionImage);
        setTitle(session?.title);
        setDescription(session?.description);
        setStartDate(new Date(session?.start).toISOString());
        setEndDate(new Date(session?.end).toISOString());
        setStartTime(handleTime(session?.startTime));
        setEndTime(handleTime(session?.endTime));
        setMinPlace(session?.minPlace);
        setTotalDuration(session?.duration);
        setMaxPlace(session?.maxPlace);
        setDiscount(session?.discount);
        setPrice(session?.price);
        setDifficulty(session?.difficulty);
        setScheduleData(session?.scheduleData);
        setSyllabus(session?.syllabus);
        setTimeWindowForCancel(session?.TimeWindowForCancel);
        setSchedule(session?.schedule);
        setSelectedDomains(
          session?.domains?.map((domain: any) => ({
            value: domain,
            label: domain,
          }))
        );
        if (session?.languageId) {
          setLanguage(
            languages.find((lang: any) => lang.value === session?.languageId)
              ?.label
          );
        }
        if (session?.isOnline) {
          setOnlineSessionLink(session?.onlineSessionLink);
        }
      } else if (course) {
        setIsOnline(false);
        setInCourse(true);
        setCourseId(course.id);
        setImage(course.image);
        setTitle(course.title);
        setDescription(course.description);
        setSyllabus(course.syllabus);
        setDiscount(course.discount);
        setPrice(course.price);
        setDifficulty(course.difficulty);
        setSelectedDomains(
          course.domains.map((domain: any) => ({
            value: domain.id,
            label: domain.name,
          }))
        );
        setSelectedSkills(
          course?.prerequisite.map((skill: any) => ({
            value: skill,
            label: skill,
          }))
        );
      }
    } else {
      navigate("/teaching");
    }
  }, [id, firstUseEffect]);

  const links = [{ path: "/mysessions", label: "My Sessions" }];

  const handleNext = async () => {
    const domains = inCourse
      ? selectedDomains?.map((domain: any) =>
          allDomains.find((dom: any) => dom.id === domain.value)
        )
      : selectedDomains?.map((domain: any) => domain.value);

    const sortedSchedule = scheduleData.schedule.sort(
      (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime()
    );
    const session: any = {
      courseId,
      SessionImage: image,
      title,
      description,
      syllabus,
      start:
        sortedSchedule.length > 0
          ? sortedSchedule[0]
          : new Date().toISOString(),
      startTime:
        sortedSchedule.length > 0
          ? sortedSchedule[0]
          : new Date().toISOString(),
      end:
        sortedSchedule.length > 0
          ? sortedSchedule[sortedSchedule.length - 1]
          : new Date().toISOString(),
      endTime:
        sortedSchedule.length > 0
          ? sortedSchedule[sortedSchedule.length - 1]
          : new Date().toISOString(),
      minPlace: parseInt(minPlace) || 0,
      maxPlace: parseInt(maxPlace) || 0,
      duration: parseInt(totalDuration) || 0,
      discount: parseInt(discount) || 0,
      price,
      difficulty,
      TimeWindowForCancel: parseInt(TimeWindowForCancel) || 0,
      isOnline,
      teacherId: user.id,
      scheduleData,
    };
    if (schedule) session.schedule = schedule;
    if (language)
      session.languageId = languages.find(
        (lang: any) => lang.label === language
      ).value;
    if (isOnline) session.onlineSessionLink = onlineSessionLink;

    if (courseId) {
      session.courseId = courseId;
    }
    if (sessionId) {
      session.sessionId = sessionId;
    }

    if (type === "new") {
      setNewSessionData({
        session,
        domains,
      });
    } else {
      setNewSessionData(session);
    }

    if (image?.lastModified) {
      await userStore.uploadPicture(image).then((response) => {
        session.SessionImage = response;
      });
    }

    setClassroomSelection(true);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="session-popup">
        <Popup isOpen={isPopupOpen} sessions={sessions} courses={courses} />
      </div>
      <Navbar />
      {classroomSelection ? (
        <ClassroomSelection
          data={newSessionData}
          isOnline={isOnline}
          setClassroomSelection={setClassroomSelection}
        />
      ) : (
        <div className="new-session">
          <div className="links">
            {links.map((link, index) => (
              <div className="link" key={index}>
                <Link to={link.path} className="label">
                  {link.label}
                </Link>
                <div className="arrow">&gt;</div>
              </div>
            ))}
            <div className="active label">
              {type === "new" ? "Add new session" : "Edit session"}
            </div>
          </div>

          <h1>{type === "new" ? "Add new session" : "Edit session"}</h1>

          <div className="inputs">
            <div className="left-column">
              <div className="field">
                <label htmlFor="picture">Course banner</label>
                <div className="input-field">
                  <UploadImage image={image} setImage={setImage} width="80%" />
                </div>
              </div>

              <div className="field">
                <div className="input-field">
                  <div className="switch-container">
                    <Switch
                      defaultChecked={isOnline}
                      onChange={() => setIsOnline(!isOnline)}
                      style={{ backgroundColor: isOnline ? "#CA2D5D" : "" }}
                    />
                    <label htmlFor="link">
                      {isOnline ? "Online" : "Offline"}
                    </label>
                  </div>
                </div>
              </div>

              {isOnline && (
                <div className="field">
                  <label htmlFor="link">Link to session</label>
                  <div className="input-field">
                    <div className="input">
                      <Input
                        type="text"
                        label="Copy link to session"
                        fullWidth
                        value={onlineSessionLink}
                        onChange={(e) => setOnlineSessionLink(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="field">
                <label htmlFor="title">Title</label>
                <div className="input-field">
                  <div className="input">
                    <Input
                      type="text"
                      label="Title"
                      readOnly={type === "new"}
                      fullWidth
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="field">
                <label htmlFor="description">Description</label>
                <div className="input-field">
                  <textarea
                    className="bio"
                    id="description"
                    placeholder="Description"
                    value={description}
                    readOnly={type === "new"}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label htmlFor="syllabus">Syllabus</label>
                <div className="input-field">
                  <TextEditor
                    text={syllabus}
                    setText={setSyllabus}
                    readOnly={type === "new"}
                  />
                </div>
              </div>

              <div className="field">
                <label htmlFor="domain">Domain</label>
                <div className="input-field">
                  <div className="input">
                    <Select
                      isMulti
                      options={domains}
                      isDisabled={type === "new"}
                      value={selectedDomains}
                      closeMenuOnSelect={false}
                      onChange={(e: any) => setSelectedDomains(e)}
                      placeholder="Select domain(s)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="right-column">
              {/* <div className="field">
                <label htmlFor="date">Date</label>
                <div className="input-field">
                  <div className="input">
                    <DateRange
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                </div>
              </div> */}

              <div className="field">
                <label htmlFor="schedule">Schedule</label>
                <div className="input-field">
                  <div className="input">
                    <RSelect
                      label="Schedule"
                      fullWidth
                      options={schedules}
                      value={schedule}
                      onChange={(e) => setSchedule(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="field">
                <label htmlFor="date">Time</label>
                <div className="input-field">
                  <div className="input">
                    <TimeRange
                      startTime={startTime}
                      setStartTime={setStartTime}
                      endTime={endTime}
                      setEndTime={setEndTime}
                    />
                  </div>
                </div>
              </div> */}

              <div className="field">
                <label htmlFor="totalDuration">
                  Total duration of the session
                </label>
                <div className="input-field">
                  <div className="input">
                    <Input
                      type="number"
                      label="totalDuration"
                      fullWidth
                      value={totalDuration}
                      onChange={(e) => setTotalDuration(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="field">
                <label htmlFor="minPlace">Minimum place</label>
                <div className="input-field">
                  <div className="input">
                    <Input
                      type="number"
                      label="minPlace"
                      fullWidth
                      value={minPlace}
                      onChange={(e) => setMinPlace(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="field">
                <label htmlFor="maxPlace">Maximum place</label>
                <div className="input-field">
                  <div className="input">
                    <Input
                      type="number"
                      label="maxPlace"
                      fullWidth
                      value={maxPlace}
                      onChange={(e) => setMaxPlace(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="field">
                <label htmlFor="difficulty">Course difficulty</label>
                <div className="input-field">
                  <div className="input">
                    <RSelect
                      label="Course difficulty"
                      fullWidth
                      disabled={type === "new"}
                      options={difficulties}
                      value={difficulty}
                      onChange={(e) => setDifficulty(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="field">
                <label htmlFor="price">Price per person</label>
                <div className="input-field">
                  <div className="input">
                    <Input
                      type="number"
                      fullWidth
                      readOnly={type === "new"}
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="field">
                <label htmlFor="discount">
                  Possible discount pourcentage (%)
                </label>
                <div className="input-field">
                  <div className="input">
                    <Input
                      type="number"
                      fullWidth
                      value={discount}
                      onChange={(e) => setDiscount(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="field">
                <label htmlFor="TimeWindowForCancel">
                  Days allowed before cancellation
                </label>
                <div className="input-field">
                  <div className="input">
                    <Input
                      type="number"
                      fullWidth
                      value={TimeWindowForCancel}
                      onChange={(e) => setTimeWindowForCancel(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="field">
                <label htmlFor="language">Language</label>
                <div className="input-field">
                  <div className="input">
                    <RSelect
                      label="Language"
                      fullWidth
                      options={languages}
                      value={language}
                      onChange={(e) => setLanguage(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              {inCourse && (
                <div className="field">
                  <label htmlFor="requiredSkill">Required skills</label>
                  <div className="input-field">
                    <div className="input">
                      <Select
                        isMulti
                        options={skills}
                        isDisabled={type === "new"}
                        value={selectedSkills}
                        onChange={(e: any) => setSelectedSkills(e)}
                        placeholder="Select required skill(s)"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="field">
            <label>Timetable</label>
            <div className="flex justify-center items-center gap-3">
              <div className="flex flex-col items-center">
                <label>Start date</label>
                <input
                  className="border border-input rounded-lg p-1.5 w-30"
                  type="date"
                  value={scheduleData?.startDate?.split("T")[0]}
                  onChange={(e) =>
                    setScheduleData({
                      ...scheduleData,
                      startDate: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex flex-col items-center">
                <label>Num days</label>
                <input
                  className="border border-input rounded-lg p-1.5 w-20"
                  type="number"
                  value={scheduleData.numDays}
                  onChange={(e) =>
                    setScheduleData({
                      ...scheduleData,
                      numDays: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex flex-col items-center">
                <label>Min time</label>
                <input
                  className="border border-input rounded-lg p-1.5 w-20"
                  type="number"
                  value={scheduleData.minTime}
                  onChange={(e) =>
                    setScheduleData({
                      ...scheduleData,
                      minTime: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex flex-col items-center">
                <label>Max time</label>
                <input
                  className="border border-input rounded-lg p-1.5 w-20"
                  type="number"
                  value={scheduleData.maxTime}
                  onChange={(e) =>
                    setScheduleData({
                      ...scheduleData,
                      maxTime: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex flex-col items-center">
                <label>Hourly chunks</label>
                <input
                  className="border border-input rounded-lg p-1.5 w-20"
                  type="number"
                  value={scheduleData.hourlyChunks}
                  onChange={(e) =>
                    setScheduleData({
                      ...scheduleData,
                      hourlyChunks: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="input-field">
              <div className="input">
                <div style={{ overflowX: "scroll" }}>
                  <ScheduleSelector
                    selection={scheduleData?.schedule}
                    hourlyChunks={scheduleData?.hourlyChunks}
                    numDays={scheduleData?.numDays || 1}
                    minTime={scheduleData?.minTime}
                    maxTime={scheduleData?.maxTime}
                    startDate={scheduleData?.startDate}
                    onChange={(e) =>
                      setScheduleData({
                        ...scheduleData,
                        schedule: e.map((date: any) => {
                          return new Date(date).toISOString();
                        }),
                      })
                    }
                    dateFormat="ddd M/D"
                    timeFormat="h:mma"
                    selectedColor="#CA2D5D"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="buttons">
            <Button
              text="Cancel"
              type="primary"
              customColor="#373737"
              width="400px"
              preventDefault={true}
              onClick={() => navigate(-1)}
            />
            <Button
              text="Next"
              type="primary"
              width="400px"
              preventDefault={true}
              onClick={handleNext}
            />
          </div>
        </div>
      )}
    </>
  );
}
