import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useStore } from "../../../../store";
import styles from "./breadcrumbs.module.css";

function PaymentBreadcrumbs() {
  const { courseStore } = useStore();
  const course: any = courseStore.courseDetails;

  const links = [
    { path: "/", label: "Home" },
    ...(course?.sessionCourse && course?.sessionCourse?.title
      ? [
          { path: "/courses", label: "Courses" },
          {
            path: `/course/${course.sessionCourse.id}`,
            label: course.sessionCourse.title,
          },
        ]
      : []),
    { path: "/courses", label: "Cohorts" },
    { path: `/cohort/${course?.id}`, label: course?.title },
  ];

  return (
    <div className={styles.course_breadcrumbs}>
      <div className={styles.course_breadcrumbs_links}>
        {links.map((link, index) => (
          <div className={styles.link} key={index}>
            <Link to={link.path} className={styles.label}>
              {link.label}
            </Link>
            <div className={styles.arrow}>&gt;</div>
          </div>
        ))}
        <div className={styles.active}>Checkout</div>
      </div>
    </div>
  );
}
export default observer(PaymentBreadcrumbs);
