import { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import { CourseComponents, Footer, Navbar } from "../../../components";
import { useStore } from "../../../store";

function Cohorte() {
  const params = useParams();
  const { courseStore, userStore } = useStore();

  const course: any = courseStore.courseDetails;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (params.id) {
      courseStore.getCohortById(params.id);
    }
    userStore.getUser();
  }, []);

  return (
    <>
      <Navbar />

      <CourseComponents.CourseBreadcrumbs type="cohort" />

      <CourseComponents.CourseHeader type="cohort" />
      <CourseComponents.CourseNavBar type="cohort" />

      <CourseComponents.AboutCourse type="cohort" />
      <CourseComponents.CourseContent />

      <CourseComponents.CourseClassrooms />

      {course?.tutors &&
      course?.tutors?.fullName &&
      course?.tutors.fullName !== "wecodeland" ? (
        <CourseComponents.AboutInstructor type="cohort" />
      ) : null}

      {course?.startTime && course?.endTime && (
        <CourseComponents.CourseSchedule />
      )}

      {(course?.sessionCourse?.objectifs?.image?.name || course?.sessionCourse?.perspectives?.images?.name) ? (
        <CourseComponents.CourseGoals type="cohort" />
      ) : null}
      <CourseComponents.CourseStories type="cohort" />
      <CourseComponents.FAQs />

      <Footer />
    </>
  );
}

export default observer(Cohorte);
