import { CourseStore } from "./course";
import { UserStore } from "./user";
import { PaymentStore } from "./Payment";

export type RootStore = {
	userStore: UserStore;
	courseStore: CourseStore;
	paymentStore: PaymentStore;
};

const rootStore: RootStore = {
	userStore: new UserStore(),
	courseStore: new CourseStore(),
	paymentStore: new PaymentStore(new UserStore()),
};

export default rootStore;