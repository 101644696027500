import React from "react";
import { observer } from "mobx-react";

import styles from "./courses.module.css";
import { Courses } from "../../../../components";
import { useStore } from "../../../../store";

interface Props {
  type: "cohort" | "course";
}

const PopularCourses: React.FC<Props> = ({ type }) => {
  const { courseStore } = useStore();

  const sessions =
    courseStore.recommendedSessions.length > 0
      ? courseStore.recommendedSessions
      : courseStore.upcomingSessions;

  const courses = type === "cohort" ? sessions : courseStore.topRatedCourses;

  const getCourseDomain = (course: any) => {    
    if (course) {
      if (course.domains) {
        return type === "cohort"
          ? course.domains[0] || ""
          : course.domains[0]?.name || "";
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  return (
    <div className={styles.courses_container}>
      <div className={styles.courses}>
        <div className={styles.titles_container}>
          <div>
            {type === "cohort" ? (
              <h1 className={styles.sub_title}>
                Developed by industry experts
              </h1>
            ) : null}

            {type === "cohort" ? (
              <h2 className={styles.title}>Our Most Popular Cohorts</h2>
            ) : type === "course" ? (
              <h2 className={styles.title}>Our Most Popular Courses</h2>
            ) : null}
          </div>

          {type === "cohort" ? (
            <p>
              Join our famous classes, the knowledge provided will definitely be
              useful for you.
            </p>
          ) : null}
        </div>

        <div className={styles.courses_list}>
          {courses.map((course: any, index) => (
            <Courses.CourseItem
              key={index}
              type={type}
              course={{
                id: course?.id,
                title: course?.title,
                category: getCourseDomain(course),
                description: course?.description,
                image:
                  type === "cohort"
                    ? course?.SessionImage?.name
                    : course?.image?.name,
                price: course?.price,
                rating: course?.rating,
                ratingCount: course?.ratingsCount,
                provider: {
                  name: course?.tutors?.fullName,
                  logo: "/images/we-code.jpeg",
                },
                startTime:
                  type === "cohort" && course?.startTime
                    ? course.startTime
                    : undefined,
                endTime:
                  type === "cohort" && course?.endTime
                    ? course.endTime
                    : undefined,
                location: course?.isOnline ? "Online" : "Synergy Space",
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(PopularCourses);
