import { Fragment, useEffect, useRef, useState } from "react";
import { Button } from "../../../../components";
import { useNavigate } from "react-router-dom";

const CourseRows: React.FC<{ data: any[] }> = ({ data }) => {
  const navigate = useNavigate();

  const [dropdown, setDropdown] = useState<boolean[]>(
    new Array(data?.length).fill(false)
  );
  const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      dropdownRefs.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target as Node)) {
          setDropdown((prev) =>
            prev.map((item, i) => (i === index ? false : item))
          );
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const options = [
    {
      name: "Edit Course",
      onClick: () => console.log("Edit Course"),
    },
  ];

  const handleDifficultColor = (difficulty: string | undefined) => {
    switch (capitalizeFirstLetter(difficulty)) {
      case "Beginner":
        return "rgb(0, 191, 165)";
      case "Intermediate":
        return "rgb(255, 138, 101)";
      case "Advanced":
        return "rgb(230, 65, 61)";
      default:
        return "#000000";
    }
  };

  function capitalizeFirstLetter(str: string | undefined): string {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <>
      {data.map((course, index) => (
        <tr key={index}>
          <td className="name">{course.title}</td>
          <td>
            {Array.isArray(course.domains) &&
              course.domains.map((domain: any, index: number) => (
                <Fragment key={index}>
                  <span>{domain}</span>
                  {index !== course.domains.length - 1 && ", "}
                </Fragment>
              ))}
          </td>
          <td style={{ color: handleDifficultColor(course.difficulty) }}>
            {capitalizeFirstLetter(course.difficulty)}
          </td>
          <td>{course.sessions} sessions</td>
          <td className="rating">
            <Button
              type="secondary"
              text="+ Add Session"
              customStyle={{
                borderStyle: "dashed",
              }}
              onClick={() => navigate("/session/new")}
            />
          </td>
          <td id="options">
            <img
              src="/images/icons/options.svg"
              alt=""
              onClick={() =>
                setDropdown((prev) =>
                  prev.map((item, i) => (i === index ? !item : item))
                )
              }
            />
            {dropdown[index] && (
              <div
                className="options"
                ref={(el) => (dropdownRefs.current[index] = el)}
              >
                {options.map((option, index) => (
                  <Fragment key={index}>
                    <span className="link" onClick={option.onClick}>
                      {option.name}
                    </span>
                    {index !== options.length - 1 && <hr />}
                  </Fragment>
                ))}
              </div>
            )}
          </td>
        </tr>
      ))}
    </>
  );
};

export default CourseRows;
