import axiosInstance from "../config/api";
import { User, UserStore } from "./user";
export class PaymentStore {
  private user: User | null = null;
  paymentMethod: "PostWire" | "Onsite" | "OnlinePayment" = "Onsite";

  constructor(private UserStore: UserStore) {}

  async createCheckoutSession(amount: number, currency: string, courseId: any) {
    const token = localStorage.getItem("token");
    try {
      // Create a Checkout Session
      const response = await axiosInstance.post(
        `/Payments/createCheckoutSession?access_token=${token}`,
        { amount, currency, courseId }
      );

      if (response.status === 200) {
        // Redirect to Stripe's hosted checkout page
        window.location.href = response.data.sessionUrl;
      } else {
        throw new Error("Failed to create Stripe Checkout session.");
      }
    } catch (error) {
      console.error("Error creating Stripe Checkout session:", error);
    }
  }

  paymentMethodHandler(method: "PostWire" | "Onsite" | "OnlinePayment") {
    this.paymentMethod = method;
  }
}
