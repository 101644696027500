import React, { useState, useEffect } from "react";
import { Editor, EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "draft-js/dist/Draft.css";

import "./editor.css";

interface TextEditorProps {
  text: any;
  setText: (text: string) => void;
  readOnly?: boolean;
}

const TextEditor: React.FC<TextEditorProps> = ({ text, setText, readOnly }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (text) {
      try {
        const contentState = readOnly
          ? convertFromRaw(text)
          : convertFromRaw(JSON.parse(text));
        setEditorState(EditorState.createWithContent(contentState));
      } catch (error) {
        console.error("Error parsing syllabus data:", error);
        setEditorState(EditorState.createEmpty());
      }
    }
  }, [text]);

  const handleEditorChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    const rawContentState = convertToRaw(newEditorState.getCurrentContent());
    setText(JSON.stringify(rawContentState));
  };

  return (
    <div className="input_container">
      <div
        style={{
          border: "1px solid #ddd",
          padding: "10px",
          minHeight: "200px",
        }}
      >
        <Editor
          editorState={editorState}
          onChange={handleEditorChange}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

export default TextEditor;
