import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../../../store";
import { Navbar, Loading } from "src/components/shared";
import Items from "./Items";
import { getFavorites } from "src/store/session";

export default function ClassroomsManagement() {
  const navigate = useNavigate();
  const { userStore } = useStore();

  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [sessions, setSessions] = useState<any[] | null>(null);
  const [courses, setCourses] = useState<any[] | null>(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const itemsPerPage = activeTab === 0 ? 5 : 6;
  const numberOfPages = count > 0 ? Math.ceil(count / itemsPerPage) : 1;

  const [isFirstEffectDone, setIsFirstEffectDone] = useState(false);
  const [user, setUser] = useState<any | null>(null);

  useEffect(() => {
    userStore.getUser().then(() => {
      const user = userStore.user;
      if (!user) {
        navigate("/login");
      } else {
        setUser(user);
      }
      setIsFirstEffectDone(true);
    });
  }, []);

  useEffect(() => {
    if (isFirstEffectDone && user?.id) {
      console.log(user?.id);
      setCount(0);
      setLoading(true);

      getFavorites(
        user?.id,
        itemsPerPage,
        itemsPerPage * (page - 1),
        activeTab === 1 ? 1 : 0
      )
        .then((response) => {
          if (response !== null) {
            console.log("response", response);
            activeTab === 0
              ? setSessions(Object.entries(response)[0][1] as any[])
              : setCourses(Object.entries(response)[0][1] as any[]);
            setCount(Object.entries(response)[1][1] as number);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [isFirstEffectDone, user, page, activeTab]);

  const links = [{ path: "/myprofile", label: "My Profile" }];

  const tabs = [
    {
      type: "cohort",
      name: "Favorite sessions",
      data: sessions,
    },
    {
      type: "course",
      name: "Favorite courses",
      data: courses,
    },
  ];

  const handleTabClick = (tab: number) => {
    setPage(1);
    setActiveTab(tab);
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < numberOfPages) {
      setPage(page + 1);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />

      <div className="table-page-container">
        <div className="links">
          {links.map((link, index) => (
            <div className="link" key={index}>
              <Link to={link.path} className="label">
                {link.label}
              </Link>
              <div className="arrow">&gt;</div>
            </div>
          ))}
          <div className="active label">Favorites</div>
        </div>

        <div className="header">
          <div className="tabs">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`tab ${activeTab === index ? "active" : ""}`}
                onClick={() => handleTabClick(index)}
              >
                {tab.name}
              </div>
            ))}
          </div>
        </div>

        <Items
          type={tabs[activeTab].type}
          data={tabs[activeTab].data}
          page={page}
          numberOfPages={numberOfPages}
          count={count}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      </div>
    </>
  );
}
