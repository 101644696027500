import { useEffect, useState } from "react";

import { useStore } from "../../../store";
import { HomeComponents, Footer, Playlist } from "../../../components";
import Beta from "src/components/shared/Navbar/Beta";

export default function Home() {
    const { userStore, courseStore } = useStore();

    const [show, setShow] = useState(true);
    
    useEffect(() => {
        courseStore.getDomains();
        courseStore.getCategories();
        courseStore.getInstructors(4);
        courseStore.getTopRatedCourses();
        courseStore.getUpcomingSessions();
        courseStore.getRecommendedSessions();
        userStore.getUser();
    }, []);
    
    return (
        <>
            <Beta show={show} setShow={setShow} />

            <div style={{marginTop: show ? "60px" : ""}}>
                <HomeComponents.Header />
                
                <main>
                    <HomeComponents.Partners partnersCount={250} />
                    <HomeComponents.PopularCourses type="cohort" />
                    <HomeComponents.Topics />
                    <HomeComponents.CurrentSessions />
                    <HomeComponents.Video />
                    <HomeComponents.PopularCourses type="course" />
                    <HomeComponents.Instructors />
                    <Playlist />
                    <HomeComponents.Steps />
                    <HomeComponents.Blogs />
                </main>

                <Footer />
            </div>
        </>
    );
}