// src/index.tsx
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import router from "./App";
import { StoreProvider } from "./store";
import { LocationStore } from "./store/location";
import Modal from "react-modal";
import { GoogleOAuthProvider } from "@react-oauth/google";
import env from "./config/env";
import './locales/i18n';

// Initialize stores
const locationStore = new LocationStore();

const AppInitializer = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeData = async () => {
      await locationStore.initializeLocationData();
      setLoading(false);
    };

    initializeData();
  }, []);

  if (loading) {
    return <div></div>;
  }

  return <>{children}</>;
};

Modal.setAppElement("#root");

const googleClientId = env.GOOGLE_CLIENT_ID!;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={googleClientId}>
      <StoreProvider>
        <AppInitializer>
          <RouterProvider router={router} />
        </AppInitializer>
      </StoreProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
