import { useEffect, useState } from "react";
import { observer } from "mobx-react";

import styles from "./form.module.css";
import { useStore } from "../../../../store";

import { loadStripe } from "@stripe/stripe-js";

import env from "../../../../config/env";

const stripePromise = loadStripe(env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentMethods = observer(
  ({
    paymentMethod,
    setPaymentMethod,
  }: {
    paymentMethod:
      | "PostWire"
      | "Onsite"
      | "OnlinePayment"
      | "KonnectPayment"
      | "StripePayment";
    setPaymentMethod: React.Dispatch<
      React.SetStateAction<
        | "PostWire"
        | "Onsite"
        | "OnlinePayment"
        | "KonnectPayment"
        | "StripePayment"
      >
    >;
  }) => {
    const { userStore } = useStore();
    const { paymentStore, courseStore } = useStore();
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
      userStore.paymentMethodHandler(paymentMethod);
    }, [paymentMethod]);

    const handlePayment = async () => {
      setIsProcessing(true);
      setError(null);
      try {
        await paymentStore.createCheckoutSession(
          courseStore.courseDetails?.price,
          "usd",
          courseStore.courseDetails?.id
        );
      } catch (err) {
        console.error("Error during Stripe Checkout:", err);
        setError("Failed to initiate payment. Please try again.");
        setIsProcessing(false);
      }
    };

    return (
      <div className={styles.payment_methods}>
        <h4>Payment Methods</h4>

        <div className={styles.payment_methods_container}>
          {userStore.user &&
            userStore.user?.country &&
            userStore.user?.country === "Tunisia" && (
              <div className={styles.payment_methods_container}>
                <div
                  className={styles.payment_method}
                  onClick={() => setPaymentMethod("Onsite")}
                >
                  <div className={styles.payment_method_name}>
                    <input
                      id="Onsite"
                      type="radio"
                      name="payment_method"
                      value={paymentMethod}
                      checked={paymentMethod === "Onsite"}
                    />
                    <label htmlFor="Onsite">Onsite</label>
                  </div>
                  <img src="/images/icons/payment-onsite.svg" alt="" />
                </div>

                <div
                  className={styles.payment_method}
                  onClick={() => setPaymentMethod("PostWire")}
                >
                  <div className={styles.payment_method_name}>
                    <input
                      id="PostWire"
                      type="radio"
                      name="payment_method"
                      checked={paymentMethod === "PostWire"}
                    />
                    <label htmlFor="PostWire">Wiring</label>
                  </div>
                  <img src="/images/icons/payment-post.svg" alt="" />
                </div>
              </div>
            )}

          <div
            className={styles.payment_method}
            onClick={() => setPaymentMethod("OnlinePayment")}
          >
            <div className={styles.payment_method_name}>
              <input
                id="OnlinePayment"
                type="radio"
                name="payment_method"
                checked={paymentMethod === "OnlinePayment"}
              />
              <label htmlFor="OnlinePayment">Online Payment</label>
            </div>
            <img src="/images/icons/payment-online.svg" alt="" />
          </div>

          {(paymentMethod === "OnlinePayment" ||
            paymentMethod === "StripePayment" ||
            paymentMethod === "KonnectPayment") && (
            <div className="w-full flex flex-col justify-start items-start space-y-3 pl-14">
              <div
                className={styles.payment_method}
                onClick={() => setPaymentMethod("StripePayment")}
              >
                <div className="w-full flex justify-start items-center space-x-4">
                  <input
                    id="StripePayment"
                    type="radio"
                    name="payment_method"
                    checked={paymentMethod === "StripePayment"}
                  />
                  <img
                    src="/images/stripe-logo.png"
                    alt=""
                    height="40rem"
                    width="40rem"
                  />
                </div>
              </div>
              <div
                className={styles.payment_method}
                onClick={() => setPaymentMethod("KonnectPayment")}
              >
                <div className={styles.payment_method_name}>
                  <input
                    id="KonnectPayment"
                    type="radio"
                    name="payment_method"
                    checked={paymentMethod === "KonnectPayment"}
                  />
                  <img
                    src="/images/konnect-logo.png"
                    alt=""
                    height="90rem"
                    width="90rem"
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={styles.payment_methods_security}>
          <img src="/images/icons/lock.svg" width={28} height={28} alt="lock" />
          <span>
            We protect your payment information using encryption to provide
            bank-level security.
          </span>
        </div>
      </div>
    );
  }
);

export default PaymentMethods;
