import axiosInstance from "../config/api";
import { makeAutoObservable, runInAction } from "mobx";

export type User = {
  country?: string;
  email?: string;
  fullName?: string;
  gender?: string;
  goal?: string;
  governorate?: string;
  password?: string;
  phone?: string;
  state?: string;
  plan?: string;
  situation?: string;
  interest?: string[];
  id?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  linkedInUrl?: string;
  githubUrl?: string;
  birthday?: Date;
};

export class UserStore {
  user: any = null;
  userData: User | null = null;
  paymentMethod: "PostWire" | "Onsite" | "OnlinePayment" | "KonnectPayment" | "StripePayment" =
    "Onsite";

  constructor() {
    makeAutoObservable(this);
  }

  setInitialState() {
    this.user = null;
    this.userData = null;
  }

  async getUser() {
    const token = localStorage.getItem("token");

    if (token) {
      const response = await axiosInstance.get(
        `/Profiles/CurrentUser?access_token=${token}`
      );
      if (response.status === 200) {
        runInAction(() => {
          this.user = response.data.user;
        });
      }
    }
  }

  setUserData(user: User) {
    runInAction(() => {
      this.userData = user;
    });
  }

  async login(email: string, password: string): Promise<string | null> {
    let data = {
      email,
      password,
    };
    const response = await axiosInstance.post(
      `/Profiles/userSpecialLogin`,
      data
    );
    if (response.status === 200) {
      runInAction(() => {
        this.user = response.data.userData;
        localStorage.setItem("token", response.data.id);
      });
      this.getUser();
      return response.data.id;
    } else {
      return null;
    }
  }

  async loginWithGoogle(token: string): Promise<string | null> {
    const response = await axiosInstance.post(`/Profiles/googleLogin`, {
      token: token,
    });
    if (response.status === 200) {
      runInAction(() => {
        this.user = response.data.userData;
        localStorage.setItem("token", response.data.id);
      });
      this.getUser();
      return response.data.id;
    } else {
      return null;
    }
  }

  async register(data: {
    // birthday: string,
    country: string;
    email: string;
    fullName: string;
    gender: string;
    goal: string;
    governorate: string;
    password: string;
    phone: string;
    state: string;
    interest: string[];
    step: number;
  }): Promise<string | null> {
    const response = await axiosInstance.post("/Profiles", {
      birthday: new Date().toISOString(),
      country: data.country,
      email: data.email,
      fullName: data.fullName,
      gender: data.gender,
      goal: data.goal,
      governorate: data.governorate,
      password: data.password,
      phone: data.phone,
      state: data.state,
      interest: data.interest,
      step: data.step,
    });
    if (response.status === 200) {
      let userId = response.data.id;
      let setStepResponse = await axiosInstance.patch(
        `/Profiles/setStep?id=${userId}&step=${data.step}`
      );
      if (setStepResponse.status === 200) {
        return setStepResponse.data.id;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  async signUpWithGoogle(token: string): Promise<string | null> {
    const response = await axiosInstance.post(`/Profiles/signUpGoogle`, {
      token: token,
    });
    if (response.status === 200) {
      runInAction(() => {
        this.user = response.data.userData;
        localStorage.setItem("token", response.data.id);
      });
      this.getUser();
      return response.data.id;
    } else {
      return null;
    }
  }

  async signUpWithLinkedIn(code: string): Promise<string | null> {
    const response = await axiosInstance.post(`/Profiles/signUpLinkedIn`, {
      code: code,
    });
    if (response.status === 200) {
      runInAction(() => {
        this.user = response.data.userData;
        localStorage.setItem("token", response.data.id);
      });
      this.getUser();
      return response.data.id;
    } else {
      return null;
    }
  }

  async loginWithLinkedIn(code: string): Promise<string | null> {
    const response = await axiosInstance.post(`/Profiles/linkedinLogin`, {
      code: code,
    });
    if (response.status === 200) {
      runInAction(() => {
        this.user = response.data.userData;
        localStorage.setItem("token", response.data.id);
      });
      this.getUser();
      return response.data.id;
    } else {
      return null;
    }
  }

  async verify(token: string, uid: string): Promise<string | null> {
    const response = await axiosInstance.get(
      `/Profiles/confirm?uid=${uid}&token=${token}`
    );
    if (response.status === 200) {
      runInAction(() => {
        localStorage.setItem("token", response.data.token);
      });
      this.getUser();
      return response.data.token;
    } else {
      return null;
    }
  }

  async forgotPassword(email: string): Promise<string | null> {
    const response = await axiosInstance.post("/Profiles/reset", {
      email: email,
    });
    if (response.status === 200 || response.status === 204) {
      return "A password reset link has been successfully sent to your email address. Please check your inbox.";
    } else {
      return null;
    }
  }

  async resetPassword(
    accessToken: string,
    newPassword: string
  ): Promise<string | null> {
    const response = await axiosInstance.post(
      `/Profiles/reset-password?access_token=${accessToken}`,
      { newPassword }
    );
    if (response.status !== 200 && response.status !== 204) {
      if (response.status === 401) {
        throw new Error("Reset password link has expired.");
      } else {
        throw new Error("Error resetting password.");
      }
    } else {
      return "Your password has successfully changes.";
    }
  }

  logout() {
    localStorage.removeItem("token");
    this.user = null;
  }

  paymentMethodHandler(
    method: "PostWire" | "Onsite" | "OnlinePayment" | "KonnectPayment" | "StripePayment"
  ) {
    runInAction(() => {
      this.paymentMethod = method;
    });
  }

  async sendUpgradeRequest(upgradeType: string, profileId: string) {
    let data: any = {
      newRole: upgradeType,
      //data: {},
      status: "Pending",
      profileId: profileId,
    };
    if (upgradeType === "teacher") {
      data.data = {};
    } else {
      data.profile = {};
    }
    let token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await axiosInstance.post(
          `/Profiles/${profileId}/upgradeRequest?access_token=${token}`,
          data
        );

        return response.status === 200
          ? {
              CODE: "SUCCESS",
              STATUS: response.status,
              MESSAGE:
                "Your upgrade to Instructor request has been successfully sent",
            }
          : {
              CODE: "ERROR",
              STATUS: response.status,
              MESSAGE: "Something went wrong. Please try again later",
            };
      } catch (error) {
        throw error;
      }
    } else {
      return {
        CODE: "ERROR",
        STATUS: 401,
        MESSAGE: "Please login to your account first.",
      };
    }
  }

  async updateProfile(id: string, data: any): Promise<string | null> {
    const token = localStorage.getItem("token");

    const response = await axiosInstance.post(
      `/Profiles/updateProfile/${id}?access_token=${token}`,
      data
    );
    if (response.status === 200) {
      runInAction(() => {
        //this.user = response.data;
        // if (!data.email && !data.password) {
        this.getUser();
        // }
      });
      return response.data;
    } else {
      return null;
    }
  }

  async confirmPassword(password: string): Promise<any> {
    const token = localStorage.getItem("token");
    const response = await axiosInstance.get(
      `/Profiles/confirmPassword?currentPassword=${password}&access_token=${token}`
    );
    if (response.status === 200) {
      runInAction(() => {
        this.user = response.data.userData;
      });
      this.getUser();
      return response.data;
    } else {
      return null;
    }
  }

  async updatePassword(password: string): Promise<string | null> {
    const token = localStorage.getItem("token");
    const response = await axiosInstance.post(
      `/Profiles/updatePassword?access_token=${token}`,
      { newPassword: password }
    );
    if (response.status === 200) {
      runInAction(() => {
        localStorage.setItem("token", response.data.id);
      });
      this.getUser();
      return response.data;
    } else {
      return null;
    }
  }

  async uploadPicture(picture: File): Promise<any> {
    const imageData = new FormData();
    imageData.append("file", picture);

    const response = await axiosInstance.post(
      "/Containers/img/upload",
      imageData
    );

    if (response.status === 200) {
      return response.data.result.files.file[0];
    } else {
      return null;
    }
  }

  async uploadFile(file: File): Promise<any> {
    const fileData = new FormData();
    fileData.append("file", file);

    const response = await axiosInstance.post(
      "/Container2s/file/upload",
      fileData
    );

    if (response.status === 200) {
      return response.data.result.files.file[0];
    } else {
      return null;
    }
  }
}
