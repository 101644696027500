const env = {
  API: process.env.REACT_APP_API_URL,
  IMAGE_API: process.env.REACT_APP_IMAGE_API_URL,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  LINKEDIN_CLIENT_ID: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
  LINKEDIN_REDIRECT_URL: process.env.REACT_APP_LINKEDIN_REDIRECT_URL,
  LINKEDIN_OAUTH_URL: process.env.REACT_APP_OAUTH_URL,
  LINKEDIN_SCOPE: process.env.REACT_APP_LINKEDIN_SCOPE,
  LINKEDIN_STATE: process.env.REACT_APP_LINKEDIN_STATE,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  REACT_APP_STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY || "",
};
export default env;
